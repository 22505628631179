<template>
  <div class="pb-12">
    <v-row>
      <v-col cols="6">
        <SituazioneProdotti />
      </v-col>
      <v-col cols="6">
        <ClientiOnline />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <OrdiniGiorno />
      </v-col>
      <v-col cols="6">
        <VisiteGiorno />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <Integrità />
      </v-col>
      <v-col cols="6">
        <ProdottiVenduti />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Analisi />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AnalisiCliente />
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import SituazioneProdotti from "./situazione_prodotti.vue";
import OrdiniGiorno from "./ordini_giorno.vue";
import VisiteGiorno from "./visite_giorno.vue";
import Integrità from "./integrità.vue";
import ProdottiVenduti from "./prodotti_venduti.vue";
import ClientiOnline from "./clienti_online/clienti_attivi_desktop.vue";
import Analisi from "./analisi/analisi.vue";
import AnalisiCliente from "./analisi_cliente/analisi_cliente.vue"

export default {
  components: {
    SituazioneProdotti,
    OrdiniGiorno,
    VisiteGiorno,
    Integrità,
    ProdottiVenduti,
    ClientiOnline,
    Analisi,
    AnalisiCliente
  },
  data() {
    return {};
  },
};
</script>