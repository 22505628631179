<template>
  <v-row>
    <v-col cols="4">
      <v-card elevation="0" class="ml-n4" height="430">
        <v-card-subtitle class="mb-n2">
          <v-row no-gutters>
            <v-col cols="6" class="text-truncate pr-2 font-weight-medium">
              Categoria
              <v-btn
                icon
                @click="
                  sort_categoria = !sort_categoria;
                  sort_by = 'Categoria';
                "
                ><v-icon>{{
                  sort_categoria ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              ></v-col
            >
            <v-col
              cols="2"
              class="font-weight-medium d-flex align-center justify-end"
              style="color: #448aff"
            >
              Blu
              <v-btn
                icon
                @click="
                  sort_blu = !sort_blu;
                  sort_by = 'Blu';
                "
                ><v-icon>{{
                  sort_blu ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="2"
              class="font-weight-medium d-flex align-center justify-end"
              style="color: #00e676"
            >
              Verde
              <v-btn
                icon
                @click="
                  sort_verde = !sort_verde;
                  sort_by = 'Verde';
                "
                ><v-icon>{{
                  sort_verde ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="2"
              class="
                font-weight-medium
                d-flex
                align-center
                justify-end
                grey--text
              "
            >
              Tot
              <v-btn
                icon
                @click="
                  sort_tot = !sort_tot;
                  sort_by = 'Totale';
                "
                ><v-icon>{{
                  sort_tot ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text class="mt-0">
          <v-virtual-scroll
            :items="analisi_categoria"
            :height="370"
            :item-height="50"
          >
            <template v-slot:default="{ item }">
              <v-row no-gutters align="center" class="py-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="6" class="text-truncate pr-2" v-on="on">
                      {{ item.categoria }}
                    </v-col>
                  </template>
                  {{ item.categoria }}
                </v-tooltip>

                <v-col cols="2" class="d-flex justify-end pr-4">
                  {{ item.tot_blu.toFixed(2) }}
                </v-col>
                <v-col cols="2" class="d-flex justify-end pr-4">
                  {{ item.tot_verde.toFixed(2) }}
                </v-col>
                <v-col cols="2" class="d-flex justify-end pr-4">
                  {{ (item.tot_verde + item.tot_blu).toFixed(2) }}
                </v-col>
              </v-row>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8">
      <VueApexCharts
        type="bar"
        :series="records"
        :options="options"
        height="430"
      />
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
export default {
  components: {
    VueApexCharts,
  },
  props: {
    ordini_analisi: Array,
  },
  data() {
    return {
      prodotto_search: "",
      sort_categoria: false,
      sort_verde: false,
      sort_blu: false,
      sort_tot: false,
      sort_by: "Categoria",
      options: {
        chart: {
          stacked: true,
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          axixBorder: {
            show: false,
          },
        },
        colors: ["#448AFF", "#00E676"],
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toFixed(2) + " €";
            },
          },
        },
      },
    };
  },
  computed: {
    analisi_categoria() {
      let lista_prod = this.$store.getters.get_analisi_prodotti;
      let gruppo = _.groupBy(lista_prod, "art");
      let lista_prodotti = [];
      Object.keys(gruppo).forEach((prodotto) => {
        lista_prodotti.push({
          categoria:
            this.$store.getters.get_prodotto_by_id(prodotto).sotto_categoria,
          tot_verde: gruppo[prodotto]
            .filter((el) => el.verde)
            .map((el) =>
              el.colori
                .map((col) => (col.qta_admin ? col.qta_admin : col.numero) * el.prezzo)
                .reduce((tot, num) => tot + num, 0)
            )
            .reduce((tot, num) => tot + num, 0),
          tot_blu: gruppo[prodotto]
            .filter((el) => !el.verde)
            .map((el) =>
              el.colori
                .map((col) => (col.qta_admin ? col.qta_admin : col.numero) * el.prezzo)
                .reduce((tot, num) => tot + num, 0)
            )
            .reduce((tot, num) => tot + num, 0),
        });
      });
      let lista_cat = _.groupBy(lista_prodotti, "categoria");
      let lista = [];
      Object.keys(lista_cat).forEach((categoria) => {
        lista.push({
          categoria: categoria,
          tot_verde: lista_cat[categoria]
            .map(element=> element.tot_verde)
            .reduce((tot, num) => tot + num, 0),
          tot_blu: lista_cat[categoria]
            .map(element=> element.tot_blu)
            .reduce((tot, num) => tot + num, 0),
        });
      });
      switch (this.sort_by) {
        case "Categoria":
          return this.sort_categoria
            ? lista.sort((a, b) =>
                a.categoria.localeCompare(b.categoria)
              )
            : lista.sort((a, b) =>
                b.categoria.localeCompare(a.categoria)
              );
        case "Blu":
          return this.sort_blu
            ? lista.sort((a, b) => a.tot_blu - b.tot_blu)
            : lista.sort((a, b) => b.tot_blu - a.tot_blu);
        case "Verde":
          return this.sort_verde
            ? lista.sort((a, b) => a.tot_verde - b.tot_verde)
            : lista.sort((a, b) => b.tot_verde - a.tot_verde);
        case "Totale":
          return this.sort_tot
            ? lista.sort(
                (a, b) => a.tot_verde + a.tot_blu - (b.tot_verde + b.tot_blu)
              )
            : lista.sort(
                (a, b) => b.tot_verde + b.tot_blu - (a.tot_verde + a.tot_blu)
              );
      }
      return null;
    },
    records() {
      return [
        {
          name: "Blu",
          data: this.analisi_categoria.map((el) => {
            return { y: el.tot_blu, x: el.categoria };
          }),
        },
        {
          name: "Verde",
          data: this.analisi_categoria.map((el) => {
            return { y: el.tot_verde, x: el.categoria };
          }),
        },
      ];
    },
  },
  methods: {
    avatar_img(prod) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        prod.id +
        "?t=" +
        prod.avatar_md5
      );
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 200px;
  overflow-y: scroll;
}
</style>