<template>
  <v-card height="700">
    <v-card-title class="grey--text text-subtitle-1">
      Analisi Clienti
      <v-spacer />
      <v-switch inset v-model="show_confronta" label="Confronta"></v-switch>
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <v-row no-gutters>
        <v-col cols="3" class="">
          <v-select
            :items="periodo_list"
            v-model="periodo"
            label="Periodo"
            item-value="value"
            item-text="label"
            hide-details
            dense
            outlined
          />
        </v-col>
        <v-col cols="3" class="pl-2" v-if="periodo == 0">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_format"
                label="Intervallo"
                outlined
                hide-details
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              range
              :allowed-dates="allowedDates"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" class="pl-2"> </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text class="mt-0">
      <template v-if="loading">
        <v-row class="pt-12" justify="center">
          <v-progress-circular width="2" color="green" indeterminate />
        </v-row>
      </template>
      <template v-else>
        <v-row justify="center" v-if="!lista_clienti.length">
          <v-btn outlined color="green" @click="fetch_analisi_cliente()"
            >Analizza</v-btn
          >
        </v-row>
        <v-row>
          <v-col cols="12" class="">
            <v-card elevation="0" height="465" class="ml-n4">
              <v-card-subtitle class="mb-n2">
                <v-row no-gutters class="mt-n4">
                  <v-col cols="2">
                    <v-text-field
                      v-model="cliente_search"
                      dense
                      hide-details
                      outlined
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Cerca Cliente ..."
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-2">
                  <v-col cols="2" class="text-truncate font-weight-medium">
                    Cliente
                    <v-btn
                      icon
                      @click="
                        sort_cliente = !sort_cliente;
                        sort_by = 'Cliente';
                      "
                      ><v-icon>{{
                        sort_cliente ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></v-btn
                    ></v-col
                  >
                  <v-col
                    cols="1"
                    class="font-weight-medium d-flex align-center justify-end"
                    style="color: #448aff"
                  >
                    Blu
                    <v-btn
                      icon
                      @click="
                        sort_blu = !sort_blu;
                        sort_by = 'Blu';
                      "
                      ><v-icon>{{
                        sort_blu ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="1"
                    class="font-weight-medium d-flex align-center justify-end"
                    style="color: #00e676"
                  >
                    Verde
                    <v-btn
                      icon
                      @click="
                        sort_verde = !sort_verde;
                        sort_by = 'Verde';
                      "
                      ><v-icon>{{
                        sort_verde ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="1"
                    class="font-weight-medium d-flex align-center justify-end grey--text"
                  >
                    Tot
                    <v-btn
                      icon
                      @click="
                        sort_tot = !sort_tot;
                        sort_by = 'Totale';
                      "
                      ><v-icon>{{
                        sort_tot ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon></v-btn
                    >
                  </v-col>
                  <template v-if="show_confronta">
                    <v-col
                      cols="1"
                      class="font-weight-medium d-flex align-center justify-end grey--text"
                    >
                      Blu Rif.
                    </v-col>
                    <v-col
                      cols="1"
                      class="font-weight-medium d-flex align-center justify-end grey--text"
                    >
                      Verde Rif.
                    </v-col>
                    <v-col
                      cols="1"
                      class="font-weight-medium d-flex align-center justify-end grey--text"
                    >
                      Totale Rif.
                    </v-col>
                    <v-col
                      cols="1"
                      class="font-weight-medium d-flex align-center justify-end grey--text"
                    >
                      Variazione
                    </v-col>
                  </template>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="mt-0">
                <v-virtual-scroll
                  :items="clienti_filtered"
                  :height="400"
                  :item-height="36"
                >
                  <template v-slot:default="{ item }">
                    <v-row no-gutters class="py-1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-col cols="2" class="text-truncate pr-2" v-on="on">
                            <v-icon size="20" color="blue">mdi-account</v-icon>
                            {{ item.cliente }}
                          </v-col>
                        </template>
                        {{ item.cliente }}
                      </v-tooltip>

                      <v-col cols="1" class="d-flex justify-end pr-4">
                        {{ item.value.blu.toFixed(2) }}
                      </v-col>
                      <v-col cols="1" class="d-flex justify-end pr-4">
                        {{ item.value.verde.toFixed(2) }}
                      </v-col>
                      <v-col cols="1" class="d-flex justify-end pr-4">
                        {{ item.value.totale.toFixed(2) }}
                      </v-col>
                      <template v-if="show_confronta">
                        <v-col cols="1" class="d-flex justify-end pr-4">
                          {{ item.rif.blu.toFixed(2) }}
                        </v-col>
                        <v-col cols="1" class="d-flex justify-end pr-4">
                          {{ item.rif.verde.toFixed(2) }}
                        </v-col>
                        <v-col cols="1" class="d-flex justify-end pr-4">
                          {{ item.rif.totale.toFixed(2) }}
                        </v-col>
                        <v-col
                          v-if="variazione(item.value.totale, item.rif.totale)"
                          cols="1"
                          class="d-flex justify-end pr-4"
                          :class="
                            variazione(item.value.totale, item.rif.totale) > 0
                              ? 'green--text'
                              : 'red--text'
                          "
                        >
                          {{ variazione(item.value.totale, item.rif.totale) }}
                          %
                        </v-col>
                        <v-col v-else cols="1" class="d-flex justify-end pr-4">
                          -
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                </v-virtual-scroll>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="8">
            <VueApexCharts
              type="bar"
              :series="records"
              :options="options"
              height="430"
            />
          </v-col> -->
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      loading: false,
      date: [
        moment().subtract(this.periodo, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      menu: false,
      modal: false,
      menu2: false,
      cliente_search: "",
      periodo_list: [
        { label: "Ultimo Giorno", value: 1 },
        { label: "Ultima Settimana", value: 7 },
        {
          label: "Ultimo Mese",
          value: moment().diff(moment().startOf("month"), "days"),
        },
        { label: "Ultimo Anno", value: moment().dayOfYear() },
        { label: "Da... a...", value: 0 },
      ],
      periodo: 7,
      lista_clienti: [],
      show_confronta: false,
      sort_by: "Totale",
      sort_tot: false,
      sort_blu: false,
      sort_verde: false,
      sort_cliente: true,
    };
  },
  watch: {
    periodo: function () {
      this.show_confronta = false;
      this.fetch_analisi_cliente();
    },
    show_confronta: function () {
      if (this.show_confronta) {
        this.fetch_confronto();
      }
    },
  },
  computed: {
    date_format() {
      return (
        moment(this.date[0]).format("DD MMMM YYYY") +
        " - " +
        moment(this.date[1]).format("DD MMMM YYYY")
      );
    },
    clienti() {
      return this.$store.getters.get_clienti;
    },
    lista_filtered() {
      return this.lista_clienti.map((element) => {
        return {
          iva: element.iva,
          cliente: this.$store.getters.get_cliente_by_iva(element.iva).nome,
          valori: {
            verde: element.res
              .filter((el) => el.verde)
              .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
              .reduce((tot, num) => tot + num, 0),
            blu: element.res
              .filter((el) => !el.verde)
              .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
              .reduce((tot, num) => tot + num, 0),
            totale: element.res
              .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
              .reduce((tot, num) => tot + num, 0),
          },
        };
      });
    },
    lista_sorted() {
      switch (this.sort_by) {
        case "Cliente":
          return this.lista_clienti
            .slice()
            .sort((a, b) => a.cliente.localeCompare(b.cliente));

        case "Blu":
          return this.lista_clienti
            .slice()
            .sort((a, b) => b.value.blu - a.value.blu);
        case "Verde":
          return this.lista_clienti
            .slice()
            .sort((a, b) => b.value.verde - a.value.verde);
        case "Totale":
          return this.lista_clienti
            .slice()
            .sort((a, b) => b.value.totale - a.value.totale);
        default:
          return this.lista_clienti;
      }
    },
    clienti_filtered() {
      if (this.cliente_search.length < 3) {
        return this.lista_sorted;
      }
      let regex = new RegExp(this.cliente_search, "i");
      return this.lista_sorted.filter((element) => {
        return regex.test(element.cliente);
      });
    },
  },
  methods: {
    fetch_analisi_cliente() {
      this.lista_clienti = [];
      this.$store
        .dispatch("fetch_analisi_clienti", {
          ts_inizio: moment().subtract(this.periodo, "days").format("X"),
          ts_fine: moment().format("X"),
        })
        .then((res) => {
          res.forEach((element) => {
            this.lista_clienti.push({
              iva: element.iva,
              cliente: this.$store.getters.get_cliente_by_iva(element.iva).nome,
              value: element.stat,
              rif: {},
            });
          });
        });
    },
    allowedDates: (val) => moment(val) < moment(),
    fetch_confronto() {
      this.$store
        .dispatch("fetch_analisi_clienti", {
          ts_inizio: moment()
            .subtract(this.periodo, "days")
            .subtract(1, "year")
            .format("X"),
          ts_fine: moment().subtract(1, "year").format("X"),
        })
        .then((res) => {
          this.lista_clienti.forEach((element) => {
            let matchitem = res.find((item) => item.iva == element.iva);
            if (matchitem) {
              element.rif = matchitem.stat;
            }
          });
        });
    },
    variazione(tot, tot_confronto) {
      let variazione = (tot * 100) / tot_confronto - 100;
      if (isNaN(variazione)) {
        return 0;
      }
      if (!isFinite(variazione)) {
        return 0;
      }
      return variazione.toFixed(0);
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 200px;
  overflow-y: scroll;
}
</style>