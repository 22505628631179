<template>
  <StatisticheDesktop v-if="!$vuetify.breakpoint.mobile" />
    <v-row justify="center" class="mt-12" v-else>Non disponibile per mobile</v-row>

</template>

<script>
import StatisticheDesktop from "@/components/Statistiche/desktop/statistiche_desktop.vue"
export default {
  components: {
    StatisticheDesktop
  },
};
</script>