import { render, staticRenderFns } from "./analisi_per_listino.vue?vue&type=template&id=3aadb556&scoped=true&"
import script from "./analisi_per_listino.vue?vue&type=script&lang=js&"
export * from "./analisi_per_listino.vue?vue&type=script&lang=js&"
import style0 from "./analisi_per_listino.vue?vue&type=style&index=0&id=3aadb556&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aadb556",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCol,VIcon,VRow,VVirtualScroll})
