<template>
  <v-row>
    <v-col cols="4">
      <v-card elevation="0" height="430">
        <v-card-subtitle class="mb-n2">
          <v-row no-gutters>
            <v-col cols="3" class="text-truncate pr-2 font-weight-medium">
              Listino
              <v-btn
                icon
                @click="
                  sort_listino = !sort_listino;
                  sort_by = 'Listino';
                "
                ><v-icon>{{
                  sort_listino ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              ></v-col
            >
            <v-col
              cols="3"
              class="font-weight-medium d-flex align-center justify-end"
              style="color: #448aff"
            >
              Blu
              <v-btn
                icon
                @click="
                  sort_blu = !sort_blu;
                  sort_by = 'Blu';
                "
                ><v-icon>{{
                  sort_blu ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="3"
              class="font-weight-medium d-flex align-center justify-end"
              style="color: #00e676"
            >
              Verde
              <v-btn
                icon
                @click="
                  sort_verde = !sort_verde;
                  sort_by = 'Verde';
                "
                ><v-icon>{{
                  sort_verde ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="3"
              class="font-weight-medium d-flex align-center justify-end grey--text"
            >
              Totale
              <v-btn
                icon
                @click="
                  sort_totale = !sort_totale;
                  sort_by = 'Totale';
                "
                ><v-icon>{{
                  sort_totale ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text class="mt-0">
          <v-virtual-scroll
            :items="analisi_listino"
            :height="370"
            :item-height="36"
          >
            <template v-slot:default="{ item }">
              <v-row no-gutters class="py-1">
                <v-col cols="3" class="text-truncate pr-2">
                  <v-icon size="20" color="blue">mdi-account</v-icon>
                  Listino {{ item.listino }}
                </v-col>
                <v-col cols="3" class="d-flex justify-end pr-4">
                  {{ item.tot_blu.toFixed(2) }}
                </v-col>
                <v-col cols="3" class="d-flex justify-end pr-4">
                  {{ item.tot_verde.toFixed(2) }}
                </v-col>
                <v-col cols="3" class="d-flex justify-end pr-4">
                  {{ (item.tot_verde + item.tot_blu).toFixed(2) }}
                </v-col>
              </v-row>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8">
      <VueApexCharts
        type="bar"
        :series="records"
        :options="options"
        height="430"
      />
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
export default {
  components: {
    VueApexCharts,
  },
  props: {
    ordini_analisi: Array,
  },
  data() {
    return {
      sort_listino: true,
      sort_blu: false,
      sort_verde: false,
      sort_totale: false,
      sort_by: "Listino",
      options: {
        chart: {
          stacked: true,
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          axixBorder: {
            show: false,
          },
        },
        colors: ["#448AFF", "#00E676"],
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toFixed(2) + " €";
            },
          },
          x: {
            formatter: (val) => {
              return "Listino" + val;
            },
          },
        },
      },
    };
  },
  computed: {
    analisi_listino() {
      let gruppo = this.ordini_analisi.map((element) => {
        return {
          ...element,
          listino: this.$store.getters.get_cliente_by_iva(element.iva).listino,
        };
      });
      gruppo = _.groupBy(gruppo, "listino");
      let lista = [];
      Object.keys(gruppo).forEach((listino) => {
        lista.push({
          listino: listino,
          tot_verde: gruppo[listino]
            .filter((el) => el.verde)
            .map((el) => (el.tot_contato != 0 ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
          tot_blu: gruppo[listino]
            .filter((el) => !el.verde)
            .map((el) => (el.tot_contato != 0 ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
        });
      });
      switch (this.sort_by) {
        case "Listino":
          return this.sort_listino
            ? lista.sort((a, b) => a.listino.localeCompare(b.listino))
            : lista.sort((a, b) => b.listino.localeCompare(a.listino));
        case "Blu":
          return this.sort_blu
            ? lista.sort((a, b) => a.tot_blu - b.tot_blu)
            : lista.sort((a, b) => b.tot_blu - a.tot_blu);
        case "Verde":
          return this.sort_verde
            ? lista.sort((a, b) => a.tot_verde - b.tot_verde)
            : lista.sort((a, b) => b.tot_verde - a.tot_verde);
        case "Totale":
          return this.sort_totale
            ? lista.sort(
                (a, b) => a.tot_verde + a.tot_blu - (b.tot_verde + b.tot_blu)
              )
            : lista.sort(
                (a, b) => b.tot_verde + b.tot_blu - (a.tot_verde + a.tot_blu)
              );
      }
      return null;
    },
    records() {
      return [
        {
          name: "Blu",
          data: this.analisi_listino.map((el) => {
            return { y: el.tot_blu, x: el.listino };
          }),
        },
        {
          name: "Verde",
          data: this.analisi_listino.map((el) => {
            return { y: el.tot_verde, x: el.listino };
          }),
        },
      ];
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 200px;
  overflow-y: scroll;
}
</style>