<template>
  <v-card height="600">
    <v-card-title class="grey--text text-subtitle-1">
      Analisi</v-card-title
    >
    <v-card-subtitle class="mt-1">
      <v-row no-gutters>
        <v-col cols="3" class="">
          <v-select
            :items="periodo_list"
            v-model="periodo"
            label="Periodo"
            item-value="value"
            item-text="label"
            hide-details
            dense
            outlined
          />
        </v-col>
        <v-col cols="3" class="pl-2" v-if="periodo == 0">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_format"
                label="Intervallo"
                outlined
                hide-details
                dense
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              range
              :allowed-dates="allowedDates"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" class="pl-2">
          <v-select
            :items="analisi_list"
            v-model="analisi"
            label="Analisi per..."
            hide-details
            dense
            outlined
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text class="mt-0">
      <template v-if="loading">
        <v-row class="pt-12" justify="center">
          <v-progress-circular width="2" color="green" indeterminate />
        </v-row>
      </template>
      <template v-else>
        <template v-if="analisi == 'Clienti'">
          <PerCliente :ordini_analisi="ordini_analisi" />
        </template>
        <template v-else-if="analisi == 'Listini'">
          <PerListino :ordini_analisi="ordini_analisi" />
        </template>
        <template v-else-if="analisi == 'Prodotti'">
          <PerProdotto :ordini_analisi="ordini_analisi" />
        </template>
        <template v-else-if="analisi == 'Categorie'">
          <PerCategoria :ordini_analisi="ordini_analisi" />
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import PerCliente from "./analisi_per_cliente.vue";
import PerListino from "./analisi_per_listino.vue";
import PerProdotto from "./analisi_per_prodotto.vue";
import PerCategoria from "./analisi_per_categoria.vue"

export default {
  components: {
    PerCliente,
    PerListino,
    PerProdotto,
    PerCategoria
  },
  mounted() {
    this.fetch_analisi_cliente();
  },
  data() {
    return {
      loading: false,
      date: [
        moment().subtract(this.periodo, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      menu: false,
      modal: false,
      menu2: false,
      periodo_list: [
        { label: "Ultimo Giorno", value: 1 },
        { label: "Ultima Settimana", value: 7 },
        { label: "Ultimo Mese", value: moment().diff(moment().startOf('month'), 'days') },
        { label: "Ultimo Anno", value: moment().dayOfYear() },
        { label: "Da... a...", value: 0 },
      ],
      periodo: 7,
      analisi_list: ["Categorie", "Clienti", "Prodotti", "Listini"],
      analisi: "Prodotti",
    };
  },
  watch: {
    periodo: function () {
      if (this.analisi == "Clienti" || this.analisi == "Listini") {
        this.fetch_analisi_cliente();
      } else if (this.analisi == "Prodotti" || this.analisi == "Categorie") {
        this.fetch_analisi_prodotti();
      }
    },
  },
  computed: {
    date_format() {
      return (
        moment(this.date[0]).format("DD MMMM YYYY") +
        " - " +
        moment(this.date[1]).format("DD MMMM YYYY")
      );
    },
    // categorie() {
    //   let lista = this.$store.getters.get_lista_prodotti;
    //   return lista.categorie.map((categoria) => {
    //     return {
    //       nome: categoria.nome,
    //       sub: categoria.sub_categorie.map((subcat) => subcat.nome),
    //     };
    //   });
    // },
    ordini_analisi() {
      return this.$store.getters.get_analisi_ordine_cliente;
    },
    // analisi_cliente() {
    //   let gruppo = _.groupBy(this.ordini_analisi, "iva");
    //   let lista = [];
    //   Object.keys(gruppo).forEach((cliente) => {
    //     lista.push({
    //       cliente: this.$store.getters.get_cliente_by_iva(cliente).nome,
    //       tot_verde: gruppo[cliente]
    //         .filter((el) => el.verde)
    //         .map((el) => (el.tot_contato != 0 ? el.tot_contato : el.tot))
    //         .reduce((tot, num) => tot + num, 0),
    //       tot_blu: gruppo[cliente]
    //         .filter((el) => !el.verde)
    //         .map((el) => (el.tot_contato != 0 ? el.tot_contato : el.tot))
    //         .reduce((tot, num) => tot + num, 0),
    //     });
    //   });
    //   switch (this.sort_by) {
    //     case "Cliente":
    //       return this.sort_cliente
    //         ? lista.sort((a, b) => a.cliente.localeCompare(b.cliente))
    //         : lista.sort((a, b) => b.cliente.localeCompare(a.cliente));
    //     case "Blu":
    //       return this.sort_blu
    //         ? lista.sort((a, b) => a.tot_blu - b.tot_blu)
    //         : lista.sort((a, b) => b.tot_blu - a.tot_blu);
    //     case "Verde":
    //       return this.sort_verde
    //         ? lista.sort((a, b) => a.tot_verde - b.tot_verde)
    //         : lista.sort((a, b) => b.tot_verde - a.tot_verde);
    //   }
    //   return null;
    // },
    // records() {
    //   return [
    //     {
    //       name: "Blu",
    //       data: this.analisi_cliente.map((el) => {
    //         return { y: el.tot_blu, x: el.cliente };
    //       }),
    //     },
    //     {
    //       name: "Verde",
    //       data: this.analisi_cliente.map((el) => {
    //         return { y: el.tot_verde, x: el.cliente };
    //       }),
    //     },
    //   ];
    // },
  },
  methods: {
    fetch_analisi_cliente() {
      this.loading = true;
      this.$store
        .dispatch("fetch_analisi_ordine", {
          ts_inizio: moment().subtract(this.periodo, "days").format("X"),
          ts_fine: moment().format("X"),
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetch_analisi_prodotti() {
      this.loading = true;
      this.$store
        .dispatch("fetch_analisi_prodotti", {
          ts_inizio: moment().subtract(this.periodo, "days").format("X"),
          ts_fine: moment().format("X"),
        })
        .finally(() => {
          this.loading = false;
        });
    },
    allowedDates: (val) => moment(val) < moment(),
  },
};
</script>

