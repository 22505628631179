var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"ml-n4",attrs:{"elevation":"0","height":"465"}},[_c('v-card-subtitle',{staticClass:"mb-n2"},[_c('v-row',{staticClass:"mt-n4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","prepend-inner-icon":"mdi-magnify","placeholder":"Cerca Cliente ..."},model:{value:(_vm.cliente_search),callback:function ($$v) {_vm.cliente_search=$$v},expression:"cliente_search"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-truncate font-weight-medium",attrs:{"cols":"6"}},[_vm._v(" Cliente "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_cliente = !_vm.sort_cliente;
                _vm.sort_by = 'Cliente';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_cliente ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-col',{staticClass:"font-weight-medium d-flex align-center justify-end",staticStyle:{"color":"#448aff"},attrs:{"cols":"2"}},[_vm._v(" Blu "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_blu = !_vm.sort_blu;
                _vm.sort_by = 'Blu';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_blu ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-col',{staticClass:"font-weight-medium d-flex align-center justify-end",staticStyle:{"color":"#00e676"},attrs:{"cols":"2"}},[_vm._v(" Verde "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_verde = !_vm.sort_verde;
                _vm.sort_by = 'Verde';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_verde ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-col',{staticClass:"\n              font-weight-medium\n              d-flex\n              align-center\n              justify-end\n              grey--text\n            ",attrs:{"cols":"2"}},[_vm._v(" Tot "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_tot = !_vm.sort_tot;
                _vm.sort_by = 'Totale';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_tot ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-0"},[_c('v-virtual-scroll',{attrs:{"items":_vm.clienti_filtered,"height":360,"item-height":36},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-col',_vm._g({staticClass:"text-truncate pr-2",attrs:{"cols":"6"}},on),[_c('v-icon',{attrs:{"size":"20","color":"blue"}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.cliente)+" ")],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.cliente)+" ")]),_c('v-col',{staticClass:"d-flex justify-end pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.tot_blu.toFixed(2))+" ")]),_c('v-col',{staticClass:"d-flex justify-end pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.tot_verde.toFixed(2))+" ")]),_c('v-col',{staticClass:"d-flex justify-end pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.tot_verde + item.tot_blu).toFixed(2))+" ")])],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('VueApexCharts',{attrs:{"type":"bar","series":_vm.records,"options":_vm.options,"height":"430"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }