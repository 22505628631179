<template>
  <v-row no-gutters>
    <v-col cols="6" class="">
      <v-avatar class="mr-4" size="35">
        <v-img :src="avatar_img" />
      </v-avatar>
      {{ nome[0] }}
    </v-col>
    <template v-if="!confronta">
      <v-col cols="1" class="d-flex justify-center">
        {{ element.qta_verde }}
      </v-col>
      <v-col cols="1" class="d-flex justify-center">
        {{ element.qta_blu }}
      </v-col>
      <v-col cols="1" class="d-flex justify-center">
        {{ element.qta_verde + element.qta_blu }}
      </v-col>
      <v-col cols="1" />
      <v-col cols="1" class="d-flex justify-center">
        {{ element.n_doc }}
      </v-col>
    </template>
    <template v-else>
      <v-col cols="2" class="d-flex justify-center">
        {{ element.qta_verde + element.qta_blu }}
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <template v-if="loading_confronto">
          <v-progress-circular
            indeterminate
            size="15"
            width="1"
            color="green"
          ></v-progress-circular>
        </template>
        <template v-else>
          {{ vendita_confronto }}
        </template>
      </v-col>
      <v-col cols="1" class="d-flex justify-center" v-if="isFinite(variazione)" :class="Math.sign(variazione) > 0 ? 'green--text' : 'red--text'"> {{variazione.toFixed(0)}} % </v-col>
      <v-col v-else cols="1" class="d-flex justify-center">-</v-col>
    </template>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  props: {
    element: Object,
    confronta: Boolean,
    ts_inizio: String,
    ts_fine: String,
  },
  data() {
    return {
      loading_confronto: false,
      vendita_confronto: 0,
    };
  },
  watch: {
    confronta: function () {
      if (this.confronta) {
        this.loading_confronto = true;
        this.$store
          .dispatch("fetch_prodotto_venduto", {
            id_prodotto: this.element.id,
            ts_inizio: moment
              .unix(this.ts_inizio)
              .subtract(1, "year")
              .format("X"),
            ts_fine: moment.unix(this.ts_fine).subtract(1, "year").format("X"),
          })
          .then((res) => {
            this.vendita_confronto = res
              .map((prod) => prod.tot)
              .reduce((tot, num) => tot + num, 0);
            this.loading_confronto = false;
          });
      }
    },
  },
  computed: {
    prodotto() {
      return this.$store.getters.get_prodotto_by_id(this.element.id);
    },
    nome() {
      return this.prodotto.nome.split(" in ");
    },
    avatar_img() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        this.prodotto.id +
        "?t=" +
        this.prodotto.avatar_md5
      );
    },
    variazione(){
        return (((this.element.qta_verde + this.element.qta_blu) * 100 / this.vendita_confronto) - 100)
    }
  },
};
</script>