<template>
  <v-card height="470">
    <v-card-title class="grey--text text-subtitle-1">
      Prodotti Venduti
      <v-spacer />
      <v-switch
        hide-details
        label="confronta"
        class="mt-n2"
        inset
        v-model="show_confronta"
      >
      </v-switch>
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <v-row no-gutters>
        <v-col cols="6" class="pr-2">
          <v-select
            :items="periodi_list"
            v-model="intervallo"
            label="Periodo"
            item-value="value"
            item-text="label"
            hide-details
            dense
            outlined
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2 mb-n2 font-weight-medium">
        <v-col cols="6">Prodotto</v-col>
        <template v-if="!show_confronta">
          <v-col
            cols="1"
            class="green--text text--accent-3 d-flex justify-center"
            ># Verde</v-col
          >
          <v-col cols="1" class="blue--text d-flex justify-center"># Blu</v-col>
          <v-col cols="1" class="grey--text d-flex justify-center"
            ># Totale</v-col
          >
          <v-col cols="1" />
          <v-col cols="1"># Ordini</v-col>
        </template>
        <template v-else>
          <v-col cols="2" class="d-flex justify-center">
            # Anno corrente
          </v-col>
          <v-col cols="2" class="d-flex justify-center"> # Anno scorso </v-col>
          <v-col cols="1" class="d-flex justify-center"> Var % </v-col>
        </template>
      </v-row>
    </v-card-subtitle>
    <v-card-text class="scroll">
      <template v-if="loading">
        <v-row justify="center" align="center" class="mt-12">
          <v-progress-circular indeterminate width="2" color="green" />
        </v-row>
      </template>
      <template v-else>
        <!-- <v-row
          v-for="element in lista"
          :key="element.id"
          no-gutters
          align="center"
          justify="center"
          class="my-1"
        >
          <v-col cols="6">
            <v-avatar class="mr-4" size="35">
              <v-img :src="avatar_img(prodotto(element.id))" />
            </v-avatar>
            {{ nome(element.id)[0] }}
          </v-col>
          <template v-if="!show_confronta">
            <v-col cols="1" class="d-flex justify-center">
              {{ element.qta_verde }}
            </v-col>
            <v-col cols="1" class="d-flex justify-center">
              {{ element.qta_blu }}
            </v-col>
            <v-col cols="1" class="d-flex justify-center">
              {{ element.qta_verde + element.qta_blu }}
            </v-col>
            <v-col cols="1" />
            <v-col cols="1" class="d-flex justify-center">
              {{ element.n_doc }}
            </v-col>
          </template>
          <template v-else>
            <v-col cols="2" class="d-flex justify-center"
              >{{ element.qta_verde + element.qta_blu }}
            </v-col>
            <v-col cols="2" class="d-flex justify-center"> </v-col>
            <v-col cols="1" class="d-flex justify-center"> </v-col>
          </template>
        </v-row> -->
        <ProdottoVendutoEl
          v-for="element in lista"
          :key="element.id"
          :element="element"
          :confronta="show_confronta"
          :ts_inizio="ts_inizio"
          :ts_fine="ts_fine"
        ></ProdottoVendutoEl>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import ProdottoVendutoEl from "./prodotto_venduto_element.vue";
export default {
  components: {
    ProdottoVendutoEl,
  },
  mounted() {
    this.fetch_prodotti();
  },
  watch: {
    intervallo: function () {
      this.show_confronta = false;
      this.fetch_prodotti();
    },
  },
  data() {
    return {
      loading: false,
      show_confronta: false,
      intervallo: 30,
      analisi: "day",
      periodi_list: [
        { label: "Ultimo Giorno", value: 1 },
        { label: "Ultima Settimana", value: 7 },
        {
          label: "Ultimo Mese",
          value: moment().diff(moment().startOf("month"), "days"),
        },
        { label: "Ultimo Anno", value: moment().dayOfYear() },
      ],
      analisi_list: [
        { label: "Giorno", value: "day" },
        { label: "Settimana", value: "week" },
        { label: "Mese", value: "month" },
      ],
      sort_totale: true,
    };
  },
  computed: {
    ts_inizio() {
      return moment().subtract(this.intervallo, "days").format("X");
    },
    ts_fine() {
      return moment().format("X");
    },
    lista() {
      let lista_prodotti = this.$store.getters.get_prodotti_venduti.map(
        (prodotto) => {
          return {
            id: prodotto.art,
            qta: prodotto.colori
              .map((col) => (col.qta_admin ? col.qta_admin : col.numero))
              .reduce((tot, num) => tot + num),
            verde: prodotto.verde,
          };
        }
      );
      let lista_group = _.groupBy(lista_prodotti, "id");
      let lista_finale = [];
      Object.keys(lista_group).forEach((key) => {
        lista_finale.push({
          id: key,
          qta_verde: lista_group[key]
            .filter((element) => element.verde)
            .map((element) => element.qta)
            .reduce((tot, num) => tot + num, 0),
          qta_blu: lista_group[key]
            .filter((element) => !element.verde)
            .map((element) => element.qta)
            .reduce((tot, num) => tot + num, 0),
          n_doc: lista_group[key].length,
        });
      });
      return lista_finale.sort(
        (a, b) => b.qta_blu + b.qta_verde - (a.qta_blu + a.qta_verde)
      );
    },
  },
  methods: {
    fetch_prodotti() {
      this.loading = true;
      this.$store
        .dispatch("fetch_prodotti_venduti", {
          ts_inizio: moment().subtract(this.intervallo, "days").format("X"),
          ts_fine: moment().format("X"),
        })
        .finally(() => {
          this.loading = false;
        });
    },
    prodotto(id) {
      return this.$store.getters.get_prodotto_by_id(id);
    },
    nome(id) {
      return this.prodotto(id).nome.split(" in ");
    },
    avatar_img(prod) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/avatar_img/" +
        prod.id +
        "?t=" +
        prod.avatar_md5
      );
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 320px;
  overflow-y: scroll;
}
</style>