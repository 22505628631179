<template>
  <v-row no-gutters>
    <v-col cols="4" class="">
      <v-card elevation="0" height="465" class="ml-n4">
        <v-card-subtitle class="mb-n2">
          <v-row no-gutters class="mt-n4">
            <v-col cols="6">
              <v-text-field
                v-model="cliente_search"
                dense
                hide-details
                outlined
                prepend-inner-icon="mdi-magnify"
                placeholder="Cerca Cliente ..."
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="">
            <v-col cols="6" class="text-truncate font-weight-medium">
              Cliente
              <v-btn
                icon
                @click="
                  sort_cliente = !sort_cliente;
                  sort_by = 'Cliente';
                "
                ><v-icon>{{
                  sort_cliente ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              ></v-col
            >
            <v-col
              cols="2"
              class="font-weight-medium d-flex align-center justify-end"
              style="color: #448aff"
            >
              Blu
              <v-btn
                icon
                @click="
                  sort_blu = !sort_blu;
                  sort_by = 'Blu';
                "
                ><v-icon>{{
                  sort_blu ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="2"
              class="font-weight-medium d-flex align-center justify-end"
              style="color: #00e676"
            >
              Verde
              <v-btn
                icon
                @click="
                  sort_verde = !sort_verde;
                  sort_by = 'Verde';
                "
                ><v-icon>{{
                  sort_verde ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
            <v-col
              cols="2"
              class="
                font-weight-medium
                d-flex
                align-center
                justify-end
                grey--text
              "
            >
              Tot
              <v-btn
                icon
                @click="
                  sort_tot = !sort_tot;
                  sort_by = 'Totale';
                "
                ><v-icon>{{
                  sort_tot ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text class="mt-0">
          <v-virtual-scroll
            :items="clienti_filtered"
            :height="360"
            :item-height="36"
          >
            <template v-slot:default="{ item }">
              <v-row no-gutters class="py-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="6" class="text-truncate pr-2" v-on="on">
                      <v-icon size="20" color="blue">mdi-account</v-icon>
                      {{ item.cliente }}
                    </v-col>
                  </template>
                  {{ item.cliente }}
                </v-tooltip>

                <v-col cols="2" class="d-flex justify-end pr-4">
                  {{ item.tot_blu.toFixed(2) }}
                </v-col>
                <v-col cols="2" class="d-flex justify-end pr-4">
                  {{ item.tot_verde.toFixed(2) }}
                </v-col>
                <v-col cols="2" class="d-flex justify-end pr-4">
                  {{ (item.tot_verde + item.tot_blu).toFixed(2) }}
                </v-col>
              </v-row>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8">
      <VueApexCharts
        type="bar"
        :series="records"
        :options="options"
        height="430"
      />
    </v-col>
  </v-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
export default {
  components: {
    VueApexCharts,
  },
  props: {
    ordini_analisi: Array,
  },
  data() {
    return {
      sort_cliente: false,
      sort_verde: false,
      sort_blu: false,
      sort_tot: false,
      sort_by: "Cliente",
      cliente_search: "",
      options: {
        chart: {
          stacked: true,
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          axixBorder: {
            show: false,
          },
        },
        colors: ["#448AFF", "#00E676"],
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return val.toFixed(2) + " €";
            },
          },
        },
      },
    };
  },
  computed: {
    clienti_filtered() {
      if (this.cliente_search.length < 3) {
        return this.analisi_cliente;
      }
      let regex = new RegExp(this.cliente_search, "i");
      return this.analisi_cliente.filter((element) => {
        return regex.test(element.cliente);
      });
    },
    analisi_cliente() {
      let gruppo = _.groupBy(this.ordini_analisi, "iva");
      let lista = [];
      Object.keys(gruppo).forEach((cliente) => {
        lista.push({
          cliente: this.$store.getters.get_cliente_by_iva(cliente).nome,
          tot_verde: gruppo[cliente]
            .filter((el) => el.verde)
            .map((el) => (el.tot_contato != 0 ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
          tot_blu: gruppo[cliente]
            .filter((el) => !el.verde)
            .map((el) => (el.tot_contato != 0 ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
        });
      });
      switch (this.sort_by) {
        case "Cliente":
          return this.sort_cliente
            ? lista.sort((a, b) => a.cliente.localeCompare(b.cliente))
            : lista.sort((a, b) => b.cliente.localeCompare(a.cliente));
        case "Blu":
          return this.sort_blu
            ? lista.sort((a, b) => a.tot_blu - b.tot_blu)
            : lista.sort((a, b) => b.tot_blu - a.tot_blu);
        case "Verde":
          return this.sort_verde
            ? lista.sort((a, b) => a.tot_verde - b.tot_verde)
            : lista.sort((a, b) => b.tot_verde - a.tot_verde);
        case "Totale":
          return this.sort_tot
            ? lista.sort(
                (a, b) => a.tot_verde + a.tot_blu - (b.tot_verde + b.tot_blu)
              )
            : lista.sort(
                (a, b) => b.tot_verde + b.tot_blu - (a.tot_verde + a.tot_blu)
              );
      }
      return null;
    },
    records() {
      return [
        {
          name: "Blu",
          data: this.analisi_cliente.map((el) => {
            return { y: el.tot_blu, x: el.cliente };
          }),
        },
        {
          name: "Verde",
          data: this.analisi_cliente.map((el) => {
            return { y: el.tot_verde, x: el.cliente };
          }),
        },
      ];
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 200px;
  overflow-y: scroll;
}
</style>