<template>
  <v-card height="470">
    <v-card-title class="grey--text text-subtitle-1">
      Integrità
      <v-spacer />
      <v-switch
        hide-details
        label="confronta"
        class="mt-n2"
        inset
        v-model="show_confronta"
      >
      </v-switch>
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <v-row no-gutters>
        <v-col cols="4" class="pr-2">
          <v-select
            :items="periodi_list"
            v-model="intervallo"
            label="Periodo"
            item-value="value"
            item-text="label"
            hide-details
            dense
            outlined
          />
        </v-col>
        <v-col cols="4" class="pl-2">
          <v-select
            :items="analisi_list"
            v-model="analisi"
            label="Analisi per..."
            item-value="value"
            item-text="label"
            hide-details
            dense
            outlined
          />
        </v-col>
        <v-col cols="4" class="pl-2" v-if="show_confronta">
          <v-select
            label="Anno di Confronto"
            outlined
            dense
            :items="anni"
            v-model="anno_confronto"
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <template v-if="loading">
        <v-row justify="center" align="center" class="mt-12">
          <v-progress-circular indeterminate width="2" color="green" />
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            <VueApexCharts
              type="bar"
              height="150"
              :options="options"
              :series="records"
            />
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-8" no-gutters>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="3">
                <span class="font-weight-medium" style="color: #00e676"
                  >Verde:
                </span>
              </v-col>
              <v-col cols="3"> {{ totali.verde.toFixed(2) }} € </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{ totali_confronto.verde.toFixed(2) }}
              </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{((totali.verde * 100 / totali_confronto.verde) - 100).toFixed(1)}} %
              </v-col>
            </v-row>
            <v-row justify="start" no-gutters>
              <v-col cols="3">
                <span class="font-weight-medium mr-2" style="color: #448aff"
                  >Blu:
                </span>
              </v-col>
              <v-col cols="3" > {{ totali.blu.toFixed(2) }} € </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{ totali_confronto.blu.toFixed(2) }}
              </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{((totali.blu * 100 / totali_confronto.blu) - 100).toFixed(1)}} %
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <span class="font-weight-medium grey--text mr-2">Totale: </span>
              </v-col>
              <v-col cols="3"> {{ totali.combinato.toFixed(2) }} € </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{ totali_confronto.combinato.toFixed(2) }} €
              </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{((totali.combinato * 100 / totali_confronto.combinato) - 100).toFixed(1)}} %
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <span class="font-weight-medium purple--text mr-2"
                  >Rapporto:
                </span>
              </v-col>
              <v-col cols="3"> {{ totali.rapporto.toFixed(0) }} % </v-col>
              <v-col cols="3" v-if="show_confronta">
                {{ totali_confronto.rapporto.toFixed(0) }} %
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="d-flex justify-center">
            <VueApexCharts
              width="150"
              type="donut"
              :options="options_pie"
              :series="records_pie"
            />
            <VueApexCharts
              v-if="show_confronta"
              width="150"
              type="donut"
              :options="options_pie_compare"
              :series="records_pie_confronto"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      ts_inizio: moment().subtract(14, "days").format("X"),
      ts_fine: moment().format("X"),
      loading: false,
      show_confronta: false,
      anno_confronto: moment().subtract(1, "year").format("YYYY"),
      intervallo: {
        inizio: moment().startOf("month").format("X"),
        fine: moment().format("X"),
      },
      analisi: "day",
      periodi_list: [
        {
          label: "Ultimo Giorno",
          value: {
            inizio: moment().startOf("day").format("X"),
            fine: moment().format("X"),
          },
        },
        {
          label: "Ultima Settimana",
          value: {
            inizio: moment().startOf("week").format("X"),
            fine: moment().format("X"),
          },
        },
        {
          label: "Ultimo Mese",
          value: {
            inizio: moment().startOf("month").format("X"),
            fine: moment().format("X"),
          },
        },
        {
          label: "Ultimo Anno",
          value: {
            inizio: moment().startOf("year").format("X"),
            fine: moment().format("X"),
          },
        },
      ],
      analisi_list: [
        { label: "Giorno", value: "day" },
        { label: "Settimana", value: "week" },
        { label: "Mese", value: "month" },
      ],
      options: {
        chart: {
          stacked: false,
          type: "bar",
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          axixBorder: {
            show: false,
          },
        },
        colors: ["#448AFF", "#00E676", "#3F51B5", "#009688"],
        stroke: {
          width: 0,
          colors: ["#fff"],
        },
        tooltip: {
          x: {
            formatter: (val) => {
              let periodo = moment.unix(val).startOf(this.analisi);
              if (this.analisi == "day") {
                return periodo.format("DD MMMM");
              } else if (this.analisi == "week") {
                return "WK " + periodo.format("WW");
              } else if (this.analisi == "month") {
                return periodo.format("MMMM");
              }
            },
          },
          y: {
            formatter: (val) => {
              return val.toFixed(2) + " €";
            },
          },
        },
      },
      options_pie: {
        labels: ["Blu", "Verde"],
        chart: {
          stacked: true,
          type: "donut",
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          axixBorder: {
            show: false,
          },
        },
        colors: ["#448AFF", "#00E676"],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return (
                ((val * 100) / (this.totali.blu + this.totali.verde)).toFixed(
                  1
                ) + "  %"
              );
            },
          },
        },
      },
      options_pie_compare: {
        labels: ["Blu", "Verde"],
        chart: {
          stacked: true,
          type: "donut",
          sparkline: {
            enabled: true,
          },
        },
        grid: {
          show: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          axixBorder: {
            show: false,
          },
        },
        colors: ["#3F51B5", "#009688"],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return (
                (
                  (val * 100) /
                  (this.totali_confronto.blu + this.totali_confronto.verde)
                ).toFixed(1) + "  %"
              );
            },
          },
        },
      },
    };
  },
  mounted() {
    this.fetch_rapporto();
  },
  watch: {
    intervallo: function () {
      this.fetch_rapporto();
      this.fetch_rapporto_confronto();
    },
    anno_confronto: function () {
      this.fetch_rapporto_confronto();
    },
  },
  computed: {
    anni() {
      let adesso = moment().format("YYYY");
      return [adesso - 1, adesso - 2, adesso - 3, adesso - 4, adesso - 5];
    },
    lista() {
      let lista_ordini = this.$store.getters.get_rapporto_verde;
      let ob_lista = _.groupBy(lista_ordini, (date) => {
        return moment.unix(date.timeStamp).startOf(this.analisi).format("X");
      });
      let lista_finale = [];
      Object.keys(ob_lista).forEach((key) =>
        lista_finale.push({
          ts: key,
          tot_verde: ob_lista[key]
            .filter((el) => el.verde)
            .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
          tot_blu: ob_lista[key]
            .filter((el) => !el.verde)
            .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
        })
      );
      return lista_finale;
    },
    lista_confronto() {
      let lista_ordini = this.$store.getters.get_rapporto_verde_confronto;
      let ob_lista = _.groupBy(lista_ordini, (date) => {
        return moment.unix(date.timeStamp).startOf(this.analisi).format("X");
      });
      let lista_finale = [];
      Object.keys(ob_lista).forEach((key) =>
        lista_finale.push({
          ts: key,
          tot_verde: ob_lista[key]
            .filter((el) => el.verde)
            .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
          tot_blu: ob_lista[key]
            .filter((el) => !el.verde)
            .map((el) => (el.tot_contato ? el.tot_contato : el.tot))
            .reduce((tot, num) => tot + num, 0),
        })
      );
      return lista_finale;
    },
    records() {
      if (this.show_confronta) {
        return [
          {
            name: "blu",
            data: this.lista.map((el) => {
              return { y: el.tot_blu, x: el.ts };
            }),
          },
          {
            name: "verde",
            data: this.lista.map((el) => {
              return { y: el.tot_verde, x: el.ts };
            }),
          },
          {
            name: "blu anno scorso",
            data: this.lista_confronto.map((el) => {
              return { y: el.tot_blu, x: el.ts };
            }),
          },
          {
            name: "verde anno scorso",
            data: this.lista_confronto.map((el) => {
              return { y: el.tot_verde, x: el.ts };
            }),
          },
        ];
      } else {
        return [
          {
            name: "blu",
            data: this.lista.map((el) => {
              return { y: el.tot_blu, x: el.ts };
            }),
          },
          {
            name: "verde",
            data: this.lista.map((el) => {
              return { y: el.tot_verde, x: el.ts };
            }),
          },
        ];
      }
    },
    records_pie() {
      return [this.totali.blu, this.totali.verde];
    },
    records_pie_confronto() {
      return [this.totali_confronto.blu, this.totali_confronto.verde];
    },
    totali() {
      let lista_ordini = this.$store.getters.get_rapporto_verde;
      let verde = lista_ordini
        .filter((element) => element.verde)
        .map((element) =>
          element.tot_contato ? element.tot_contato : element.tot
        )
        .reduce((tot, sum) => tot + sum, 0);
      let blu = lista_ordini
        .filter((element) => !element.verde)
        .map((element) =>
          element.tot_contato ? element.tot_contato : element.tot
        )
        .reduce((tot, sum) => tot + sum, 0);
      return {
        verde: verde,
        blu: blu,
        combinato: blu + verde,
        rapporto: (blu * 100) / (verde + blu),
      };
    },
    totali_confronto() {
      let lista_ordini = this.$store.getters.get_rapporto_verde_confronto;
      let verde = lista_ordini
        .filter((element) => element.verde)
        .map((element) =>
          element.tot_contato ? element.tot_contato : element.tot
        )
        .reduce((tot, sum) => tot + sum, 0);
      let blu = lista_ordini
        .filter((element) => !element.verde)
        .map((element) =>
          element.tot_contato ? element.tot_contato : element.tot
        )
        .reduce((tot, sum) => tot + sum, 0);
      return {
        verde: verde,
        blu: blu,
        combinato: blu + verde,
        rapporto: (blu * 100) / (verde + blu),
      };
    },
  },
  methods: {
    fetch_rapporto() {
      this.loading = true;
      this.$store
        .dispatch("fetch_rapporto_verde", {
          ts_inizio: this.intervallo.inizio,
          ts_fine: this.intervallo.fine,
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetch_rapporto_confronto() {
      let ts_inizio = moment
        .unix(this.intervallo.inizio)
        .year(this.anno_confronto)
        .format("X");
      let ts_fine = moment
        .unix(this.intervallo.fine)
        .year(this.anno_confronto)
        .format("X");
      this.$store
        .dispatch("fetch_rapporto_verde_confronto", {
          ts_inizio: ts_inizio,
          ts_fine: ts_fine,
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>