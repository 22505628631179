var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"ml-n4",attrs:{"elevation":"0","height":"430"}},[_c('v-card-subtitle',{staticClass:"mb-n2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-truncate pr-2 font-weight-medium",attrs:{"cols":"6"}},[_vm._v(" Categoria "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_categoria = !_vm.sort_categoria;
                _vm.sort_by = 'Categoria';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_categoria ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-col',{staticClass:"font-weight-medium d-flex align-center justify-end",staticStyle:{"color":"#448aff"},attrs:{"cols":"2"}},[_vm._v(" Blu "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_blu = !_vm.sort_blu;
                _vm.sort_by = 'Blu';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_blu ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-col',{staticClass:"font-weight-medium d-flex align-center justify-end",staticStyle:{"color":"#00e676"},attrs:{"cols":"2"}},[_vm._v(" Verde "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_verde = !_vm.sort_verde;
                _vm.sort_by = 'Verde';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_verde ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-col',{staticClass:"\n              font-weight-medium\n              d-flex\n              align-center\n              justify-end\n              grey--text\n            ",attrs:{"cols":"2"}},[_vm._v(" Tot "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.sort_tot = !_vm.sort_tot;
                _vm.sort_by = 'Totale';}}},[_c('v-icon',[_vm._v(_vm._s(_vm.sort_tot ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-0"},[_c('v-virtual-scroll',{attrs:{"items":_vm.analisi_categoria,"height":370,"item-height":50},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-col',_vm._g({staticClass:"text-truncate pr-2",attrs:{"cols":"6"}},on),[_vm._v(" "+_vm._s(item.categoria)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.categoria)+" ")]),_c('v-col',{staticClass:"d-flex justify-end pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.tot_blu.toFixed(2))+" ")]),_c('v-col',{staticClass:"d-flex justify-end pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.tot_verde.toFixed(2))+" ")]),_c('v-col',{staticClass:"d-flex justify-end pr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.tot_verde + item.tot_blu).toFixed(2))+" ")])],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('VueApexCharts',{attrs:{"type":"bar","series":_vm.records,"options":_vm.options,"height":"430"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }